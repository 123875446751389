import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const MobileLogotypesSection = () => {
    return (
        <div className='container-fluid bg-light d-flex justify-content-evenly align-items-center d-md-none' style={{ height: "100px" }}>
            <div>
                <StaticImage
                    src='../../images/logos/dark/rosaventi_logo_dark.png'
                    layout="constrained"
                    alt=""
                    height={50}
                    transformOptions={{ fit: `inside` }}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>
            <div>
                <StaticImage
                    src='../../images/logos/dark/zzw-logo-dark.png'
                    layout="constrained"
                    alt=""
                    height={50}
                    transformOptions={{ fit: `inside` }}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>
            <div>
                <StaticImage
                    src='../../images/logos/dark/warszawa-logo-dark.png'
                    layout="constrained"
                    alt=""
                    height={50}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>
            <div >
                <StaticImage
                    src='../../images/logos/dark/wisla-logo-dark.png'
                    layout="constrained"
                    alt=""
                    height={50}
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    objectFit='contain'
                    className='my-2 mx-3'
                />
            </div>

        </div>
    )
}

export default MobileLogotypesSection
