import * as React from "react"
import PropTypes from "prop-types"

import Footbar from "../footbar"

import "../../scss/season-opening-2023/layout.scss"

const Layout = ({ children }) => {

  return (
    <div className="container-fluid p-0 overflow-hidden">
      <main className="min-vh-100">
        {children}
      </main>
      <Footbar />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
